import * as React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { BusinessSelector } from './components';
import { selectedBusinessSelector } from '@/api/store/slices/businessSlice';
import { Item, Items, Link, Wrapper } from './styles';

type Props = {};

export const Navigation = (props: Props) => {
  const selectedBusiness = useSelector(selectedBusinessSelector);
  const router = useRouter();

  let navigationOptions = [
    { href: '/', name: 'Home' },
    {
      push: true,
      badge: null,
      href: '/settings',
      name: 'Settings',
    },
  ];

  if (selectedBusiness.id) {
    navigationOptions = [
      ...navigationOptions,
      { href: '/users', name: 'Users' },
    ];
  }

  const query: {
    businessId?: string | string[];
    redirectUrl?: string | string[];
  } = {};

  if (router.query.businessId) {
    query.businessId = router.query.businessId;
  }

  if (router.query.redirectUrl) {
    query.redirectUrl = router.query.redirectUrl;
  }

  return (
    <Wrapper $open={false} data-testid="navigation_wrapper">
      <Items data-testid="navigation_items">
        {navigationOptions.map((link, index) => (
          <Item
            key={link.name + index}
            data-testid={`navigation_item_${link.name}`}
          >
            <Link
              href={{
                pathname: link.href,
                query,
              }}
              $active={
                link.href.length > 1
                  ? router.pathname.startsWith(link.href)
                  : router.pathname === link.href
              }
              data-testid={`navigation_link_${link.name}`}
            >
              {link.name}
            </Link>
          </Item>
        ))}
      </Items>
      <Link
        href={`${process.env.NEXT_PUBLIC_ACCESS_APP_BASE_URL}/logout`}
        data-testid="navigation_link_logout"
      >
        Logout
      </Link>
      <BusinessSelector data-testid="navigation_business_selector" />
    </Wrapper>
  );
};
