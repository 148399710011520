import { useState } from 'react';

import { Arrow, Button, Items, Item } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Business,
  selectedBusinessSelector,
  setSelectedBusiness,
} from '@/api/store/slices/businessSlice';
import { useRouter } from 'next/router';
import { useDefaultBusiness } from '@/components/apps/hooks/useDefaultBusiness';

export const BusinessSelector = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();

  const { isLoading, sessionData } = useDefaultBusiness();

  const selectedBusiness = useSelector(selectedBusinessSelector);
  const { businesses } = sessionData?.data || {};

  if (isLoading || !selectedBusiness.id) {
    return null;
  }

  const toggleMenu = () => {
    setOpen(!open);
  };

  const onClickBusiness = (newSelectedBusiness: Business) => {
    dispatch(setSelectedBusiness(newSelectedBusiness));
    router.query.businessId = newSelectedBusiness.id;
    router.push(router);
    toggleMenu();
  };

  const businessCount = businesses?.length;

  return (
    <>
      {businesses.length < 1 ? (
        <></>
      ) : (
        <Items
          $open={open}
          $businessCount={businessCount}
          data-testid="business_selector_container"
        >
          <Item>
            <Button
              $selected
              onClick={toggleMenu}
              data-testid="toggle_business_selection"
            >
              {selectedBusiness.legalName}
              <Arrow $direction={open ? 'up' : 'down'}>{/* SVG path */}</Arrow>
            </Button>
          </Item>
          {open &&
            businesses
              .filter(
                (business: Business) => business.id !== selectedBusiness.id,
              )
              .map((business: Business) => (
                <Item key={business.id}>
                  <Button
                    onClick={() => onClickBusiness(business)}
                    data-testid={`select_business_${business.id}`}
                  >
                    {business.legalName}
                  </Button>
                </Item>
              ))}
        </Items>
      )}
    </>
  );
};
